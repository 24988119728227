$black-txt : #000000;
$gray-txt: #5E6366;
$white-txt: #FFFFFF;
$border: #CFD3D4;
$red-txt: #FF2A2A;

.black {
    color: $black-txt;
}

.redtxt {
    color: $red-txt;
}

.graytxt {
    color: $gray-txt !important;
}

.bg-litBlue {
    background-color: #DAF6FF !important;
}

.drBlue {
    background: #DAF6FF;
}

.lgBlue {
    background: #EAFAFF;
}

.graycr {
    background-color: #FAFAFA;
}