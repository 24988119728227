.btnMinW {
    min-width: 110px;
    width: fit-content;
}

.mdltxt {
    color: #25293B;
    font-size: 18px;
    font-family: DMSans_med;
}

.modal_ttl {
    font-family: DMSans_bold;
    font-size: 22px;
}

.modal_sub_ttle {
    font-family: DMSans_bold;
    font-size: 18px;
}

.footer_modal {
    padding: 15px;
}

.modal {
    &.infoModal .modal-dialog {
        max-width: 500px;
    }

    .modal-dialog {
        max-width: 400px;



        .btnMinW {
            min-width: 85px;
        }
    }
}

.form_txtview {
    background: #F8F8F8;
}