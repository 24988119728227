.ant-drawer-content-wrapper {
    .up .ant-drawer-body {
        background-color: #000;
        padding-left: 0;
        padding-right: 0;
    }
}

.profile_heading {
    font-size: 28px;
    font-family: DMSans_med;
}

.uploadinput {
    min-width: 100px;
    height: 100px;
    width: 100px;
    border: 1px solid #E3E3E3;
    border-radius: 10px;
}

.uploaded-img {
    .uploaded-img-view {
        min-width: 100%;
        aspect-ratio: 1/1;
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
    }

    .btndlt-img-upl {
        position: absolute;
        right: -10px;
        top: -10px;
        border: 0;
        background: transparent;
        cursor: pointer;
    }
}

.lagbox {
    font-size: 12px;
    padding: 4px 12px;
    background: #e6f0fc;
    width: fit-content;
    border-radius: 50px;
    margin: 4px 8px 4px 0;
    font-family: Outfit_reg;
}

.whiteBox {
    background: #FFFFFF;
    padding: 12px;
    border-radius: 8px;
}

.bor_dash_btm {
    border-bottom: dashed 1px #D1D1D1;
    padding-bottom: 10px;
}

.profile_main-img {
    aspect-ratio: 1 / 1;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    object-fit: cover;
}

.viewWebsite {
    font-size: 12px;
    color: #000;
    padding: 7px 4px;
    background: #fff;
    border-radius: 4px;
    border: 0;
    font-family: Outfit_reg;
}

.profile_ontxt {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: url("../../../public/img/profileDownshdw.png") no-repeat bottom center;
    background-size: 100% 100%;
}

.change_img-profile {
    background: #EEEEEE;
    position: absolute;
    cursor: pointer;
    border-radius: 4px;
    bottom: 20px;
    padding: 10px 14px;
    width: fit-content;
    left: 20px;
    z-index: 1;

    label {
        color: #000000;
    }
}

.grid3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-right: -15px;

    .uploaded-img {
        margin-right: 15px;
    }
}