.tbwrp {
    width: 100%;
    overflow: auto;
    max-height: 68vh;
    min-height: 40vh;
    position: relative;

    &.ios {
        height: 50vh;
    }
}

.eyesw {
    width: 24px;
}

.totleValue {
    font-size: 20px;
    font-family: Outfit_reg;
}

.minW1470 {
    @media (min-width: 1200px) {
        min-width: 1470px;
    }

}

.action-btn-vfd {
    border: 1px solid #28C18D;
    font-size: 12px;
    padding: 4px 10px;
    background: #e9f9f4;
    border-radius: 20px;
    min-width: 75px;
    font-family: Outfit_reg;
}

.action-btn-rjt {
    border: 1px solid #F15E79;
    font-size: 12px;
    padding: 4px 10px;
    background: #FEEFF2;
    border-radius: 20px;
    min-width: 75px;
    font-family: Outfit_reg;
}

table {
    width: 100%;
    border-collapse: collapse;

    .action {
        width: 150px;
        min-width: 150px;
    }

    thead {
        th {
            vertical-align: middle;
            font-size: 12px;
            padding: 8px 10px;
            background: #F6F6F6;
            font-family: DMSans_med;
            white-space: nowrap;
            color: $gray-txt;
            position: sticky;
            text-transform: capitalize;
            top: 0;
            z-index: 1;

            &:first-child {
                padding-left: 20px;

            }

            &:last-child {
                padding-right: 20px;

            }


        }
    }

    tbody {
        tr:nth-child(even) {
            background-color: #F6F6F6;
        }

        td {
            font-size: 12px;
            padding: 13px 10px;
            // border-bottom: 1px solid #E1E1E1;
            vertical-align: middle;
            font-family: DMSans_med;
            word-break: break-word;
            white-space: normal;
            color: $black-txt;
            // text-transform: capitalize;

            @media (max-width: 575px) {
                padding: 4px 10px;
            }

            &:first-child {
                padding-left: 20px;

            }

            &:last-child {
                padding-right: 20px;

            }
        }


    }

    .tbl-checkbox {
        width: 1.6rem;
        height: 1.6rem;
        position: relative;
        top: 3px;
    }


}

:where(.css-dev-only-do-not-override-1k979oh).ant-pagination .ant-pagination-item-active {
    border-color: #006A8E !important;
    background-color: #006A8E !important;
    color: #fff !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-pagination .ant-pagination-item {
    background-color: #F1F2F6;
    color: #8B909A;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover {
    border-color: #006A8E !important;
    background-color: #006A8E !important;
    color: #fff !important;
}

.paggtbl {
    // margin-top: 20px;

    .ant-pagination {
        margin-left: auto;
        width: fit-content;

        .ant-pagination-options {
            display: none;
        }
    }
}

.td_activebtn {
    background: #009718;
    color: #fff;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 12px;
    max-width: 90px;
    text-align: center;
    display: block;
}

.td_deactivebtn {
    background: #F65656;
    color: #fff;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 12px;
    max-width: 90px;
    text-align: center;
    display: block;
}