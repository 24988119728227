.logo_mobile {
    display: none;
}


.form-control {
    box-shadow: none !important;
}

@media (min-width: 1400px) and (max-width: 1600px) {
    .min-wd120 {
        min-width: 120px;
    }

    .header .search_icon {
        min-width: 225px;
    }

    .msch .me-xxl-4 {
        margin-right: 0.7rem !important;
    }

    aside {
        min-width: 180px;
        width: 180px;
    }

    .dashboard_wrp {
        width: calc(100% - 180px);
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .min-wd120 {
        min-width: 110px;

    }

    .max200 {
        max-width: 146px;
    }

    .header .dropdownheader .dropdown-toggle {
        width: 135px !important;
    }

    .header .search_icon {
        min-width: 225px;
    }

    .msch .me-xxl-4 {
        margin-right: 0.7rem !important;
    }

    aside {
        min-width: 180px;
        width: 180px;
    }

    .dashboard_wrp {
        width: calc(100% - 180px);
    }

    .min-wd120.me-3 {
        margin-right: 5px !important;
    }
}


// @media (max-width: 1199px) {
//     .mobile_12none {
//         display: none !important;
//     }



//     .logo_mobile {
//         display: flex;
//         position: absolute;
//         left: 20px;
//         top: 23px;
//     }

//     .dashboard_wrp .header {
//         flex-wrap: wrap;
//         padding-top: 80px;

//         .msch {
//             width: 100%;
//         }

//         .end_col {
//             position: absolute;
//             right: 20px;
//             top: 23px;
//         }

//         .search {
//             width: 100%;

//             .search_icon {
//                 min-width: 100%;
//             }
//         }
//     }

//     .filter_mobile {
//         width: 100%;
//         margin-top: 15px;
//         // white-space: nowrap;
//         overflow: auto;
//         flex-wrap: wrap;

//         .filter-dropdown,
//         .form-control {
//             margin-bottom: 10px;
//         }

//         .form-control.max200 {
//             max-width: 110px;
//         }

//         .max200 {
//             width: fit-content;
//         }

//         .dropdownheader {
//             position: static;

//             button {
//                 position: relative;
//             }
//         }
//     }

//     .logout-dropdown {
//         font-family: DMSans_med !important;
//         color: #1B1C1D;
//         font-size: 16px;
//         background: #000;
//         height: 35px;
//         width: 35px;
//         padding: 4px;
//         display: flex !important;
//         align-items: center !important;
//         justify-content: center !important;
//         border-radius: 50%;

//         // img {
//         //     width: 100%;
//         // }

//         button {
//             background-color: transparent;
//             box-shadow: none;
//         }

//         .dropdown-menu {
//             border-radius: 10px;
//             min-width: 103px;
//             padding: 5px 0;
//             margin-right: 1rem;
//             border-color: #E1E1E1;
//         }
//     }

//     aside {
//         position: fixed;
//         left: 0;
//         right: 0;
//         width: 100%;
//         height: initial;
//         bottom: 0;
//         z-index: 99;
//         background: #DEDEDE;
//     }

//     .sidebar_grp {
//         display: flex;
//         align-items: center;
//         list-style-type: none;
//         // justify-content: space-evenly;
//         justify-content: space-between;
//         margin-bottom: 0;

//         &.loginside {
//             justify-content: space-evenly;
//         }

//         a.active {
//             background-image: none;
//         }

//         li {
//             margin-bottom: 0;
//         }
//     }

//     .dash_content {
//         padding-bottom: 100px !important;
//     }
// }

.mobileH {
    display: none;
}

@media (max-width: 991px) {
    .mobile_boltxt {
        font-family: DMSans_bold;
        color: #000 !important;
    }

    table thead th {
        display: none;
    }

    .mobileH {
        display: block;
    }

    .mobileH {
        font-size: 16px;
        font-family: DMSans_med;
        color: #687182;
    }

    .tbwrp {
        overflow: inherit;
        max-height: initial;
        min-height: initial;
        position: relative;
    }

    tbody {
        tr {
            display: flex;
            flex-wrap: wrap;
            border: 1px solid #EFEFEF;
            border-radius: 8px;
            margin: 14px 0;

            td {
                display: block;
                width: 33.333333333333336%;

                &:first-child {
                    padding-left: 10px;

                }

                &:last-child {
                    padding-right: 10px;

                }
            }
        }
    }
}

// @media (max-width: 767px) {
//     .mobile_rm {
//         display: none !important;
//     }
// }

// @media (min-width: 768px) {
//     .mobile_show {
//         display: none !important;
//     }
// }

@media (max-width: 600px) {
    tbody tr td {
        width: 50%;
    }

    .mobileH {
        font-size: 14px;
    }
}

@media (max-width: 575px) {


    .logo_mobile {
        left: 20px;

        img {
            max-width: 150px;
        }
    }

    .sidebar_grp a {
        flex-direction: column;
        line-height: 1;

        span {
            margin-left: 0 !important;
            font-size: 14px;
            margin-top: 5px;
        }
    }

    .dash_content {
        padding: 30px 10px;
    }

    .dashboard_wrp .header .search .search_icon {
        min-height: 40px;
        font-size: 14px;
    }

    .header .dropdownheader .dropdown-toggle {
        min-height: 40px;
        font-size: 14px !important;
    }

    .dropdownheader .dropdown-toggle {
        padding: 4px 15px;
    }

    .dropdownheader .dropdown-toggle::after {
        top: 17px;
    }

    .filter_mobile .form-control.max200 {
        min-height: 40px;
    }
}

.filterHBox {

    @media (min-width: 1200px) {
        display: none;
    }

    @media (max-width: 575px) {
        img {
            min-width: 40px;
            width: 40px;
            height: 40px;
        }
    }
}