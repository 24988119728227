.dashboard {
    background: #F7F8FA;
}

aside {
    width: 260px;
    height: 100vh;
    overflow: auto;
    min-width: 260px;
    position: relative;
    border-right: 1px solid #E1E1E1;
    background: #fff;
    padding-right: 20px;

    @media (max-width: 1350px) {
        width: 200px;
        min-width: 200px;
    }
}

.logo_dash {
    padding: 23px 0;
    line-height: 0;
    margin-bottom: 30px;

    img {
        max-width: 140px;
    }
}

.sidebar_grp {
    li {
        margin-bottom: 34px;
    }

    a {
        padding: 15px 19px;
        color: #646464;
        text-decoration: none !important;
        line-height: 0;
        color: #646464;
        background-color: #F6F6F6;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        font-family: DMSans_med;
        font-size: 14px;
        line-height: normal;

        &.active {
            background-color: #006A8E;
            color: #fff;
            font-family: DMSans_bold;

            img {
                filter: brightness(5);
            }
        }

        &:hover {
            background-color: #006A8E;
            color: #fff;
            font-family: DMSans_bold;

            img {
                filter: brightness(5);
            }
        }
    }
}

.sider_lts_inn-grp {
    list-style: none;

    .sider_lts_inn-itm {
        position: relative;
        z-index: 1;
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }

        div {
            margin-bottom: 0 !important;
            margin-top: 20px;
            font-size: 14px;
            color: #818094;
            margin-left: 10px;
        }

        &::after {
            position: absolute;
            content: "";
            height: 41px;
            width: 1px;
            background: #A1A1A1;
            top: -29px;
            left: -5px;
        }

        &:first-child {
            &::after {
                height: 32px;
                top: -20px;
            }
        }

        &::before {
            position: absolute;
            content: "";
            height: 1px;
            width: 12px;
            background: #A1A1A1;
            left: -5px;
            top: 11px;
        }
    }
}

.dashboard_wrp {
    width: calc(100% - 260px);

    @media (max-width: 1350px) {
        width: calc(100% - 200px);
    }

    @media (max-width: 1199px) {
        width: 100%;
    }

    .header {
        border-bottom: 1px solid #E1E1E1;
        background: #fff;
    }
}

.locationCon-time {
    display: flex;
    background-color: #E9EDF5;
    padding: 2px 8px;
    border-radius: 5px;
    align-items: center;
    width: fit-content;

    .time-ttl {
        font-size: 12px !important;
        color: #9A9A9A !important;
        margin-right: 5px;
        font-family: "DMSansR";
    }

    .time-data {
        color: #464F60 !important;
        font-size: 14px !important;
        font-family: "DMSansM";
    }

}

.location-info {
    padding-top: 6px;
}

.location-info-item {
    padding: 10px 0;
    border-bottom: 1px solid #EEEEEE;

    h4 {
        margin-bottom: 0;
        font-family: DMSans_bold;

        @media (max-width: 1350px) {
            font-size: 14px !important;
        }
    }

    span {
        font-size: 14px;
        font-family: DMSans_med;


        @media (max-width: 1350px) {
            font-size: 12px !important;
        }

        &:first-child {
            color: #999999;
            margin-right: 5px;

        }

        &:last-child {
            color: #000;
            font-family: DMSans_bold;
        }
    }

    &:last-child {
        border-bottom: 0;

    }
}

.jobDisC_grp {
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    max-width: 75%;

    @media (max-width: 1199px) {
        max-width: 100%;
    }
}

.jobDisC {
    padding: 10px;
    font-size: 12px;

    @media (max-width: 991px) {
        width: 100%;
        flex-wrap: wrap;
    }
}

.jobDisC-item {
    word-break: break-word;

    span {
        &:first-child {
            color: #687182;
            font-family: DMSans_med;
            margin-right: 4px;
        }
    }

    margin-right: 7px;
    position: relative;
    padding-right: 7px;

    &::after {
        position: absolute;
        content: "";
        width: 1px;
        height: 20px;
        background: #E1E1E1;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &:last-child {
        margin-right: 0px;
        padding-right: 0px;

        &::after {
            display: none;
        }
    }

    @media (max-width: 991px) {
        width: 33.33%;
        margin-right: 0;
        margin-top: 5px;
        margin-bottom: 5px;

        &::after {
            display: none;
        }
    }

    @media (max-width: 600px) {
        width: 50%;
    }
}

.filter_mobile.filterToggle {
    @media (max-width: 1199px) {
        display: none !important;
    }

}

.fildrpDwn {

    .dropdown-item.active,
    .dropdown-item:active {
        background-color: #fff;
        color: #1F1F1F;

        img {
            display: inline-block;
        }

    }

    .dropdown-item {
        color: #687182;
        font-size: 14px;
        border-bottom: 1px solid #D8D8D8;

        img {
            display: none;
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    .dropdown-menu.show {
        min-width: 120px;
        margin-top: 6px;
    }
}